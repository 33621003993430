import { Box, Stack, styled } from "@mui/material"
import { Outlet, useLocation } from "react-router-dom"
import MainFooter from "layouts/main/MainFooter"
import MainHeader from "layouts/main/MainHeader"
import { HEADER } from "config"

const MainStyle = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  minHeight: "100vh",
  backgroundColor: theme.palette.grey[500]
}))

export default function MainLayout() {
  const { pathname } = useLocation()

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <MainStyle>
        <Outlet />
      </MainStyle>

      <Box sx={{ flexGrow: 1 }} />

      <MainFooter isAssessment={!pathname.includes("/assessment")} />
    </Stack>
  )
}
