import axiosInstance from "utils/axios"

export const fetchOrgPicture = async () => {
  try {
    const response = await axiosInstance.post("/SystemUser/org-picture", undefined, {
      responseType: "blob"
    })

    const urlCreator = window.URL
    const imageUrl = urlCreator.createObjectURL(response?.data)

    return imageUrl
  } catch (error) {
    throw new Error("Failed to fetch org picture")
  }
}

export const fetchSystemUser = async (accessToken) => {
  if (!!accessToken) {
    const res = await axiosInstance.post("/SystemUser/info", null, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    return res.data
  } else {
    throw new Error("Not found token")
  }
}
