import { fetchSystemUser } from "api/user"
import { STATUS_CODE } from "constants"
import { upperCase } from "lodash"
import axiosInstance from "utils/axios"

export const changePassword = async (values) => {
  const { data } = await axiosInstance.post("/Auth/change-password", {
    oldPassword: values.oldPassword,
    newPassword: values.newPassword
  })

  if (data?.isSucceed) return data
  else throw data.message
}

export const login = async ({ username, password }) => {
  const { data } = await axiosInstance.post("/Auth/keycloak/challenge", { username, password })

  if (data?.mfaEnable) return { ...data }

  if (data?.accessToken) {
    const { userInfo } = await fetchSystemUser(data.accessToken)

    return { accessToken: data?.accessToken, user: userInfo }
  } else {
    throw new Error(data.message)
  }
}

export const verify = async (req) => {
  try {
    const {
      data: { status, token: accessToken, message }
    } = await axiosInstance.post("/Auth/keycloak/2fa", {
      Code: req.code,
      temporaryToken: req.token
    })

    if (upperCase(status) === STATUS_CODE.SUCCESS) {
      const { userInfo } = await fetchSystemUser(accessToken)
      return { user: userInfo, accessToken }
    } else {
      throw new Error(message)
    }
  } catch (error) {
    return new Error(error.message)
  }
}
