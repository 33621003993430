import { Avatar, Box, Link, Stack, Typography } from "@mui/material"
import useAuth from "hooks/useAuth"
import Image from "components/Image"
import { useNavigate } from "react-router-dom"
import { fetchOrgPicture } from "api/user"
import { useQuery } from "@tanstack/react-query"

export default function MainAccount() {
  const { logout, user } = useAuth()
  const navigate = useNavigate()

  const { data: orgPicture } = useQuery(["org-picture"], fetchOrgPicture)

  const handleLogout = () => {
    logout()
    navigate("/main")
  }

  return (
    <>
      <Image src={orgPicture} alt="org-logo" sx={{ height: "70px" }} />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Stack sx={{ textAlign: "right" }}>
          <Box sx={{ display: "flex" }}>
            <Typography component="p" variant="body1" sx={(theme) => ({ color: theme.palette.grey[200] })}>
              Welcome,{" "}
              <Typography component="span" variant="body1" sx={(theme) => ({ color: theme.palette.common.black })}>
                {user.firstName}
              </Typography>
            </Typography>
          </Box>

          <Box>
            <Link
              component="button"
              noWrap
              underline="none"
              sx={{ textAlign: "right", color: (theme) => theme.palette.primary.light, fontWeight: 500 }}
              onClick={() => navigate("/main/update-password")}
            >
              Change Password
            </Link>
          </Box>

          <Box>
            <Link component="button" noWrap underline="none" sx={{ textAlign: "right" }} onClick={handleLogout}>
              Logout
            </Link>
          </Box>
        </Stack>
        <Avatar />
      </Box>
    </>
  )
}
